@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap');

body {
  margin: 0;
  font-family: 'Cairo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.MuiCardHeader-title.MuiTypography-displayBlock {
  display: block;
  color: #6057a1 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  text-align: end;
  position: relative;
  top: -5px;
}

.MuiCardHeader-title.MuiTypography-displayBlock:lang(en) {
  text-align: right;
}
.MuiCardHeader-title.MuiTypography-displayBlock:lang(ar) {
  text-align: left;
}

.swiper-button-prev,
.swiper-button-next {
  color: rgba(255, 255, 255, 0.9) !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 25px;
}

.filter .swiper-pagination {
  display: none;
}

/* .swiper-container,
.swiper-wrapper {
  display: flex;
  align-items: flex-end;
} */

/* .swiper-wrapper {
  display: flex;
  align-items: flex-end;
} */

/* .filter .swiper-slide {
  width: auto !important;
  margin: 0 !important;
} */

/* .filter .swiper-container {
  width: 100%;
  height: 100%;
} */
